import React, { useState, useEffect } from "react";
import axios from "axios";
import ParticlesBackground from "./components/ParticlesBackground";

function App() {

  const [counter, setCounter] = useState();
  // Call fetchData on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.counterapi.dev/v1/Stirlingjob69/jobapplied/");
        setCounter(response.data.count);
        // console.log(response)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
  };
    fetchData();
  }, []);

  return (
     <div className="App">
      <ParticlesBackground />
      <div className="container">
          <h1>How Many Jobs Has Stirling Applied For</h1>
          <div>
            <h1>Job Counter: {counter}</h1>
          </div>
      </div>


     </div>
  );
}

export default App;
